import type { RecursivePartial } from '#types/utils'
import type { CartConfig } from '#types/config/components/cart'

export default {
  brandClasses: {
    header: 'b-grey-50 mb-4 lg:mb-6',
    items: 'pb-0 md:pb-12',
    itemsPerShipping: 'b-grey-50',
    noItems: 'mx-a py-16 md:py-20 -mt-4 lg:-mt-6 text-center title-2'
  },
  coupon: {
    brandClasses: {
      formError: 'mt-1',
      pillsWrapper: 'flex wrap mt-2 gap-2',
      pillsTitle: 'text-xs'
    },
    buttonVariant: 'secondary'
  },
  item: {
    actionMenu: {
      brandClasses: {
        button: 'px-4 py-3 gap-3',
        list: 'divide-grey-50'
      },
      dialogOptions: { type: 'panel' },
      iconSize: 'md'
    },
  },
  loyalty: {
    showEachRewardInSummary: false,
    showLoyaltyOnEmptyCart: true,
    useVouchers: false
  },
  outOfStock: {
    dialogOptions: { type: 'panel' },
    showHeader: true,
    showPrice: true,
    showRemoveAll: true,
    showRemoveItem: true,
    showSaveForLater: true
  },
  quickshop: {
    dialogOptions: {
      type: {
        md: 'panel',
        lg: 'modal'
      },
      position: 'bottom'
    }
  },
  savedForLater: {
    dialogOptions: { type: 'panel' },
    moveToCartIcon: 'bag',
    savedForLaterPageSize: 6
  },
  showCheckoutCta: true,
  showItemLevelPromocode: true,
  showOrderLevelPromocodeOnItem: true,
  thumbnails: {
    size: { width: 94, height: 94 }
  },
  cartItem: {
    thumbnails: {
      width: { sm: 94, lg: 128 },
      height: { sm: 94, lg: 128 }
    }
  },
  stickyCtas: {
    enabled: true
  },
  variantOrder: ['color', 'size', 'width', 'length'],
  openInNewTab: () => false
} satisfies RecursivePartial<CartConfig>
